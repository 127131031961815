import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

export interface ICounterSlice {
  value: number;
  loading: boolean;
  departureAirports: IAirport[] | null;
  flights: any,
}

export interface IAirport extends IBaseirport {
  destination_to: IAirport[],
}


export interface IBaseirport {
  city_name: string,
  country_code: string,
  iata_code: string,
  name: string,
}

const initialState: ICounterSlice = {
  value: 0,
  loading: false,
  departureAirports: null,
  flights: null
};

export const counterSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    fetchDepartureAirports: state => {
      state.loading = true;
    },
    fetchDepartureAirportsSuccess: (state, action: PayloadAction<IAirport[]>) => {
      state.loading = false;
      state.departureAirports = action.payload;
    },

    fetchFlights: state => {
      state.loading = true;
    },

    fetchFlightsSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.flights = action.payload;
    }
  },
});

export const { actions: counterActions, reducer: counterReducer } = counterSlice;
