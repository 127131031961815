import React, { useEffect } from 'react';
import '../App.css';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import FlightTicket from '../components/FlightTicket';
import FlightFilter from '../components/FlightFilter';
import { counterActions } from '../redux/counter/slice';
import { Box } from '@mui/material';
import { isEmpty } from 'lodash';

function App(): JSX.Element {
  const dispatch = useAppDispatch();

  const { departureAirports, flights, loading } = useAppSelector(state => state.counter);

  useEffect(() => {
    dispatch(counterActions.fetchDepartureAirports());
  }, []);

  const getFlightsAction = (payload: any): void => {
    dispatch(counterActions.fetchFlights(payload));
  };

  if (!departureAirports) {
    return <Box>loading</Box>;
  }

  console.log(flights);

  return (
    <div className="App">
      <FlightFilter airports={departureAirports} getFlights={getFlightsAction} />
      {loading && <Box>Loading</Box>}
      {!loading && flights && (
        <>
          {!isEmpty(flights.outbound_flight) && <FlightTicket flight={flights.outbound_flight} totalCost />}
          {!isEmpty(flights.return_flight) && <FlightTicket flight={flights.return_flight} />}
        </>
      )}
    </div>
  );
}

export default App;
