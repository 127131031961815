import React, { type FC } from 'react';
import { Box, Typography } from '@mui/material';
import { combineSxProps, type MuiSxProps } from '../../theme';
import ArrowIcon from '../Icons/ArrowIcon';

interface PriceProps {
  sx?: MuiSxProps;
  from: string;
  to: string;
}

const FlightDirection: FC<PriceProps> = ({ sx, from, to }) => {
  const styles: MuiSxProps = combineSxProps(
    () => ({
      color: '#05253A',
      fontSize: '16px',
      fontWeight: 700,
    }),
    sx,
  );

  return (
    <Box display="flex" flexDirection="row" gap={2} alignItems="center">
      <Typography sx={styles}>{from}</Typography>
      <ArrowIcon
        sx={{
          width: '65px',
        }}
      />
      <Typography sx={styles}>{to}</Typography>
    </Box>
  );
};

export default FlightDirection;
