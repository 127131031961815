import React, { type ReactNode, type FC } from 'react';
import { Button as MButton } from '@mui/material';
import { combineSxProps, type MuiSxProps } from '../../theme';

interface ButtonProps {
  sx?: MuiSxProps;
  children: ReactNode;
  onClick: () => void;
}

const Button: FC<ButtonProps> = ({ sx, children, ...props }) => {
  const styles: MuiSxProps = combineSxProps(
    () => ({
      width: '180px',
      height: '60px',
      borderRadius: '50px',
      color: '#FFFFFF',
    }),
    sx,
  );

  return (
    <MButton {...props} sx={styles} variant="contained">
      {children}
    </MButton>
  );
};

export default Button;
