import { SvgIcon, type SvgIconProps } from '@mui/material';
import React, { type FC, memo } from 'react';

const ArrowIcon: FC<SvgIconProps> = ({ sx, fontSize, color }) => (
  <SvgIcon width="22" height="18" viewBox="0 0 22 18" fill="none" sx={{fill: 'none'}}>
   <path d="M8.93669 1L2.625 7H20.625" stroke="#FF725C" strokeWidth="2" strokeLinecap="round"/>
   <path d="M13.0633 17L19.375 11H1.375" stroke="#FF725C" strokeWidth="2" strokeLinecap="round"/>
  </SvgIcon>
);

export default memo(ArrowIcon);

