import { SvgIcon, type SvgIconProps } from '@mui/material';
import React, { type FC, memo } from 'react';

const ArrowIcon: FC<SvgIconProps> = ({ sx, fontSize, color }) => (
  <SvgIcon viewBox="0 0 65 16" sx={sx} fontSize={fontSize} color={color}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M57.9632 0.970115C57.3945 0.367813 56.4451 0.340648 55.8428 0.90944C55.2405 1.47823 55.2133 2.42759 55.7821 3.02989L59.0592 6.50001H2.10156C1.27314 6.50001 0.601562 7.17158 0.601562 8.00001C0.601562 8.82843 1.27314 9.50001 2.10156 9.50001H59.0592L55.7821 12.9701C55.2133 13.5724 55.2405 14.5218 55.8428 15.0906C56.4451 15.6594 57.3945 15.6322 57.9632 15.0299L63.6294 9.02989L64.602 8L63.6294 6.97012L57.9632 0.970115Z"
      fill="#FF725C"
    />
  </SvgIcon>
);

export default memo(ArrowIcon);
