import { type MuiSxProps, type SystemStyleObject } from './types';

export const combineSxProps = (sx1?: MuiSxProps, sx2?: MuiSxProps, sx3?: MuiSxProps)
: MuiSxProps => {
  let sx: MuiSxProps = [];

  if (Array.isArray(sx1)) {
    sx = [...sx1];
  } else if (sx1 != null) {
    sx = [sx1 as SystemStyleObject];
  }

  if (Array.isArray(sx2)) {
    sx = [sx, ...sx2];
  } else if (sx2 != null) {
    sx = [...sx, sx2 as SystemStyleObject];
  }

  if (Array.isArray(sx3)) {
    sx = [...sx, ...sx3];
  } else if (sx3 != null) {
    sx = [...sx, sx3 as SystemStyleObject];
  }

  return sx;
};

