import React, { type FC } from 'react';
import './FlightFilter.css';
import {
  Box,
  FormControl,
  FormControlLabel,
  Grid2,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
} from '@mui/material';
import { format } from 'date-fns';
import Button from '../Button';
import { DatePicker } from '@mui/x-date-pickers';
import ArrowsRepeat from '../Icons/ArrowsRepeat';
import { type IAirport } from '../../redux/counter/slice';

const CustomDatePicker: FC<any> = ({ ...props }) => (
  <DatePicker
    {...props}
    slotProps={{ textField: { variant: 'standard' } }}
    sx={{
      width: '100%',
      '& .MuiInput-root': {
        '&:hover > fieldset': { borderColor: '#C7C8CD' },
        height: '60px',
        border: 'none',
      },
    }}
  />
);

interface IFlightFilter {
  airports: IAirport[];
  getFlights: (payload: any) => void;
}

const FlightFilter: FC<IFlightFilter> = ({ airports, getFlights }) => {
  const [departureAirport, setDepartureAirport] = React.useState<IAirport>(airports[0]);
  const [destinationAirport, setDestinationAirport] = React.useState<IAirport>(airports[0].destination_to[0]);
  const [ticketType, setTicketType] = React.useState('oneWay');
  const [departureDate, setDepartureDate] = React.useState<any>(new Date());
  const [returnDate, setReturnDate] = React.useState<any>(null);

  const handleChangeticketsType = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setTicketType((event.target as HTMLInputElement).value);
  };

  const handleChangeDirection = (): void => {
    const newDepartureAirport = airports.find(airport => airport.iata_code === destinationAirport.iata_code);
    if (Object.keys(newDepartureAirport as IAirport).length > 0) {
      setDepartureAirport(newDepartureAirport as IAirport);
      setDestinationAirport(departureAirport);
    }
  };

  const handleSearchClick = (): void => {
    getFlights({
      departureAirport: departureAirport.iata_code,
      destinationAirport: destinationAirport.iata_code,
      departureDate: format(departureDate, 'yyyy-MM-dd'),
      returnDate: returnDate ? format(returnDate, 'yyyy-MM-dd') : null,
    })
  }

  return (
    <Box className="container">
      <Box paddingLeft={3}>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          name="radio-buttons-group"
          onChange={handleChangeticketsType}
          value={ticketType}
          sx={{
            flexDirection: 'row',
          }}
        >
          <FormControlLabel value="oneWay" control={<Radio />} label="One way" />
          <FormControlLabel value="return" control={<Radio />} label="Return" />
        </RadioGroup>
      </Box>
      <Box
        sx={{
          width: '100%',
          height: '60px',
          background: '#FFFFFF',
          borderRadius: '50px',
        }}
      >
        <Grid2 container height="60px">
          <Grid2 height="60px" size={3}>
            <FormControl sx={{ m: 1, width: '100%', height: '60px', margin: 0 }}>
              <Select
                value={departureAirport}
                onChange={event => {
                  setDepartureAirport(event.target.value as IAirport);
                  setDestinationAirport((event.target.value as IAirport).destination_to[0]);
                }}
                renderValue={selected => {
                  return selected.name;
                }}
                sx={{
                  width: '100%',
                  height: '100%',
                  borderRadius: '50px 0 0 50px',
                  textAlign: 'left',
                  paddingRight: '20px',
                }}
              >
                {airports.map(airport => (
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-expect-error
                  <MenuItem key={airport.iata_code} value={airport}>
                    {airport.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid2>
          <Grid2 height="60px" size={0} zIndex={20}>
            <IconButton
              sx={{
                borderRadius: '40px',
                border: '1px solid #FF725C',
                width: '35px',
                height: '35px',
                alignSelf: 'center',
                marginLeft: 'calc(-35px/2)',
                marginTop: '12.5px',
              }}
              color="primary"
              aria-label="add to shopping cart"
              onClick={handleChangeDirection}
            >
              <ArrowsRepeat />
            </IconButton>
          </Grid2>
          <Grid2 height="60px" size={3}>
            <FormControl sx={{ m: 1, width: '100%', height: '60px', margin: 0 }}>
              <Select
                value={destinationAirport}
                onChange={event => {
                  setDestinationAirport(event.target.value as IAirport);
                }}
                renderValue={selected => {
                  return selected.name;
                }}
                sx={{
                  width: '100%',
                  height: '100%',
                  textAlign: 'left',
                  paddingLeft: '20px',
                }}
              >
                {departureAirport.destination_to.map(airport => (
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-expect-error
                  <MenuItem key={airport.iata_code} value={airport}>
                    {airport.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid2>
          <Grid2 height="60px" size={2}>
            <CustomDatePicker
              value={departureDate}
              onChange={setDepartureDate}
              disablePast
              maxDate={(Boolean(returnDate)) || null}
            />
          </Grid2>
          {/* <Divider orientation="vertical" flexItem sx={{ mr: "-1px" }} /> */}
          <Grid2 height="60px" size={2}>
            <CustomDatePicker
              disabled={ticketType === 'oneWay'}
              value={returnDate}
              minDate={(Boolean(departureDate)) || new Date()}
              onChange={setReturnDate}
              disablePast
            />
          </Grid2>
          <Grid2 height="60px" size={2} textAlign="end">
            <Button onClick={handleSearchClick}>Search</Button>
          </Grid2>
        </Grid2>
      </Box>
    </Box>
  );
};

export default FlightFilter;
