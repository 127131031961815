import React from 'react';
import ReactDOM from 'react-dom/client';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { Provider } from 'react-redux';
import { store } from './redux/store';
import ThemeProvider from './theme';
import './locales/i18n';
import Route from './Route';


const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ThemeProvider>
        <Provider store={store}>
          <Route />
        </Provider>
      </ThemeProvider>
    </LocalizationProvider>
  </React.StrictMode>,
);
