import axios from "axios";
import queryString from 'query-string';

export const REQUEST_TIMEOUT = 30000;

export const paramsSerializer = (data: any): string => {
  const parsedData: Record<string, unknown> = {};

  if (typeof data === 'object') {
    Object.keys(data).forEach((key) => {
      const filterValue = data[key];
      if ((Boolean(filterValue)) || filterValue === 0 || filterValue === false) {
        parsedData[key] = filterValue;
      }
    });
  }


  return queryString.stringify(parsedData);
};

export const httpInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: REQUEST_TIMEOUT,
  paramsSerializer,
});


// esbioInstance.interceptors.response.use(undefined, rejectedHandler);

export default httpInstance;