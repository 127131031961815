import { call, type CallEffect, put, takeEvery, type ForkEffect, type PutEffect } from 'redux-saga/effects';
import { counterActions, type IAirport, } from './slice';
import { getAirports, getFlights } from './api';
import { type ServerResponse } from 'http';
import { type AnyAction } from 'redux-saga';

export function* fetchDepartureAirports(
): Generator<CallEffect<ServerResponse<any>> | PutEffect<AnyAction>, void>  {
  try {
    const data = yield call(getAirports);
    yield put(counterActions.fetchDepartureAirportsSuccess(data as IAirport[]));
  } catch (error) {
    console.log(error)
  }
}

export function* fetchFlights({payload}: any): Generator<CallEffect<ServerResponse<any>> | PutEffect<AnyAction>, void> {
  try {
    const data = yield call(getFlights, payload);
    yield put(counterActions.fetchFlightsSuccess(data))
  } catch (error) {
    console.log(error);
  }
}

export function* watchCounterSagas(): Generator<ForkEffect, void> {
    yield takeEvery(counterActions.fetchDepartureAirports,
    fetchDepartureAirports,
  );
    yield takeEvery(counterActions.fetchFlights,
    fetchFlights,
  );
}

const counterSagas = watchCounterSagas;

export default counterSagas;
