import React, { type FC } from "react";
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
// import Stepper from "./components/Stepper";
import { Box } from "@mui/system";
import App from "./pages/App";

const Route: FC = () => {

  const routes = [
    {
      path: '/',
      element: <App />,
      errorElement: <div>404!</div>,
      step: 1,
    },
    {
      path: '/step2',
      element: <Box>step2</Box>,
      step: 2,
    }
  ]
  
  const router = createBrowserRouter(routes);

  console.log(router);

  console.log(router.state)

return (
 <>
  <Box margin="20px 0" width="100%" textAlign="center"><img src={require('../src/components/assets/Logo.png')}></img></Box>
  <Box className="test">
   {/* <Stepper step={1} totalSteps={routes.length} /> */}
   <RouterProvider router={router} />
  </Box></>
 
)
}

export default Route