import React, { type FC } from 'react';
import { Box, Typography } from '@mui/material';
import { type MuiSxProps } from '../../theme';

interface PriceProps {
  sx?: MuiSxProps;
  from: string,
  to: string
}

const CompaniesBunch: FC<PriceProps> = ({ sx, from, to }) => {
  return (
    <Box display="flex" flexDirection="row" gap={2} alignItems="center">
      <img
        height="30px"
        width="75px"
        src={from}
        style={{
          marginRight: '10px',
        }}
      />
      <Typography sx={{
        color: '#0E193C',
        fontSize: '28px',
      }}>+</Typography>
      <img
        height="30px"
        width="75px"
        src={to}
        style={{
          marginRight: '10px',
        }}
      />
    </Box>
  );
};

export default CompaniesBunch;
