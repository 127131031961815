import React, { type ReactNode, type FC } from 'react';
import { Typography } from '@mui/material';
import { combineSxProps, type MuiSxProps } from '../../theme';

interface PriceProps {
  sx?: MuiSxProps,
  children: ReactNode,
}

const Price: FC<PriceProps> = ({sx, children}) => {

  const styles: MuiSxProps = combineSxProps(() => ({
    color: 'primary.main',
    fontSize: '44px',
    fontWeight: 700,
  }), sx);

  return (
    <Typography
    sx={styles}
    >{children}</Typography>
  )
}

export default Price;