/* eslint-disable @typescript-eslint/explicit-function-return-type */
import http, {paramsSerializer} from '../baseHttp';

export const getAirports = async () => {
  const { data } = await http.get(`/api/airports`);
  return data;
};

export const getFlights = async (payload: any) => {
  const { data } = await http.get(`/api/flights`,
    {
      paramsSerializer,
      params: {
        departure_airport: payload.departureAirport,
        arrival_airport: payload.destinationAirport,
        departure_date: payload.departureDate,
        return_date: payload.returnDate || null,
      },
    },
  );
  return data;
};