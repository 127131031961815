import { createTheme, type Shadows } from '@mui/material/styles';
import { enUS } from '@mui/material/locale';
import { MuiTypography } from './typography';
import { palette } from './palette';

const defaultTheme = createTheme();

export const breakpointValues = {
  xs: 0,
  sm: 600,
  md: 900,
  lg: 1400,
  xl: 1920,
};

const theme = createTheme({
  breakpoints: {
    values: breakpointValues,
  },
  palette,
  typography: {
    button: {
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: 1,
    },
  },

  shadows: [
    'none',
    '0px 4px 15px rgba(0, 0, 0, 0.15)',
    '1px 1px 7px 1px rgba(0, 0, 0, 0.25)',
    '5px 5px 15px rgba(0, 0, 0, 0.25)',
    '0px 0px 15px rgba(0, 0, 0, 0.15)',
    '2px 2px 10px rgba(0, 0, 0, 0.1), -2px -2px 10px rgba(0, 0, 0, 0.05)',
    '0px 4px 10px rgba(0, 0, 0, 0.07)',
    '0px 0.5px 1.75px rgba(0, 0, 0, 0.039), 0px 1.85px 6.25px rgba(0, 0, 0, 0.19)',
    '0px 3px 13px rgba(0, 0, 0, 0.039), 0px 10.5px 36px rgba(0, 0, 0, 0.19)',
    '0px 3px 13px rgba(0, 0, 0, 0.039), 0px 10.5px 16px rgba(0, 0, 0, 0.19)',
    '0px 0px 15px 0px rgba(0, 0, 0, 0.25)',
    ...defaultTheme.shadows.slice(10),
  ] as Shadows,
  shape: {
    borderRadius: 5,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontFamily: 'sans-serif',
          overflowY: 'auto',
        },
        a: {
          textDecoration: 'none',
        },
        '*::-webkit-scrollbar': {
          width: 10,
          height: 10,
          background: '#FFF',
          borderRadius: 8,
          border: '2px solid white',
        },
        '*::-webkit-scrollbar-thumb': {
          background: '#A8A8A8',
          borderRadius: 10,
          borderLeft: '2px solid white',
          borderRight: '2px solid white',
        },
        '*::-webkit-scrollbar-track': {
          background: '#D9D9D9',
          borderRadius: 10,
          border: '2px solid white',
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: ({ theme: currentTheme }) => ({
          position: 'relative',
          [currentTheme.breakpoints.up('lg')]: {
            paddingRight: currentTheme.spacing(6.5),
            paddingLeft: currentTheme.spacing(6.5),
          },
          [currentTheme.breakpoints.up('md')]: {
            paddingRight: currentTheme.spacing(6),
            paddingLeft: currentTheme.spacing(6),
          },
          [currentTheme.breakpoints.down('sm')]: {
            paddingRight: currentTheme.spacing(4),
            paddingLeft: currentTheme.spacing(4),
          },
        }),
      },
    },
    MuiTypography,
    MuiButton: {
      defaultProps: { color: 'primary' },
      styleOverrides: {
        root: {
          fontFamily: 'sans-serif',
          padding: '10px 20px',
          textTransform: 'none',
        },
      },
    },
    MuiIconButton: {
      defaultProps: { color: 'primary' },
    },
    MuiSvgIcon: {
      variants: [
        {
          props: { fontSize: 'large' },
          style: {
            fontSize: 35,
          },
        },
        {
          props: { fontSize: 'small' },
          style: {
            fontSize: 18,
          },
        },
      ],
    },
    MuiDialog: {
      styleOverrides: {
        container: {
          background: 'rgba(0, 0, 0, 0.65)',
          backdropFilter: 'blur(2px)',
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiPagination: {
      styleOverrides: {
        root: ({ theme: currentTheme }) => ({
          padding: '32px',
          display: 'flex',
          justifyContent: 'end',
          [currentTheme.breakpoints.down('sm')]: {
            paddingRight: currentTheme.spacing(2),
            paddingLeft: currentTheme.spacing(2),
          },
        }),
      },
    },
    MuiChip: {
      styleOverrides: {
        root: ({ theme: currentTheme }) => ({
          fontFamily: 'sans-serif',
          backgroundColor: currentTheme.palette.common.white,
          boxShadow: currentTheme.shadows[2],
          borderRadius: '7px',
          height: '26px',
        }),
        label: ({ theme: currentTheme }) => ({
          fontSize: '14px',
          lineHeight: 1,
          padding: currentTheme.spacing(0.75, 1),
          color: currentTheme.palette.text.secondary,
        }),
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontFamily: 'sans-serif',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: ({ theme: currentTheme }) => ({
          fontFamily: 'sans-serif',
          '& input': {
            fontFamily: 'sans-serif',
          },
          '& input::placeholder, textarea::placeholder': {
            fontFamily: 'sans-serif',
            color: currentTheme.palette.grey[400],
            opacity: 1,
          },
        }),
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          '& svg': {
            fontSize: '18px',
          },
        },
      },
    },
    MuiAvatarGroup: {
      styleOverrides: {
        avatar: {
          fontFamily: 'sans-serif',
          fontSize: '14px',
          fontWeight: 500,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontFamily: 'sans-serif',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontFamily: 'sans-serif',
        },
        asterisk: {
          marginLeft: '-3px',
        },
      },
    },

    MuiTab: {
      styleOverrides: {
        root: {
          fontFamily: 'sans-serif',
          letterSpacing: '-0.32px',
        },
      },
    },
  },
}, enUS);

export default theme;
