import {
  type ComponentsOverrides, type ComponentsProps, type ComponentsVariants, type Theme,
} from '@mui/material';


export const MuiTypography: {
  defaultProps?: ComponentsProps['MuiTypography'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiTypography'];
  variants?: ComponentsVariants['MuiTypography'];
} = {
  styleOverrides: {
    root: {
      fontFamily: '\'Nunito\', sans-serif',
      overflowWrap: 'break-word',
    },
    h1: ({ theme }) => ({
      fontSize: '6.354vw',
      fontWeight: 900,
      lineHeight: 1,
      textTransform: 'uppercase',
      [theme.breakpoints.down('xl')]: {
        fontSize: '122px',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: '90px',
      },
      [theme.breakpoints.down('md')]: {
        fontSize: '75px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '32px',
      },
    }),
    h2: ({ theme }) => ({
      fontFamily: 'Mossport',
      textTransform: 'uppercase',
      fontSize: '3.645vw',
      lineHeight: 1,
      [theme.breakpoints.down('xl')]: {
        fontSize: '70px',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: '60px',
      },
      [theme.breakpoints.down('md')]: {
        fontSize: '50px',
      },
    }),
    h3: ({ theme }) => ({
      fontFamily: 'Mossport',
      textTransform: 'uppercase',
      fontSize: '48px',
      lineHeight: 1,
      [theme.breakpoints.up('xl')]: {
        fontSize: '2.5vw',
      },
    }),
    h4: ({ theme }) => ({
      fontFamily: 'Mossport',
      textTransform: 'uppercase',
      fontSize: '32px',
      lineHeight: 1,
      [theme.breakpoints.up('xl')]: {
        fontSize: '1.666vw',
      },
    }),
    h5: ({ theme }) => ({
      fontSize: '30px',
      lineHeight: 1.1,
      letterSpacing: '-0.32px',
      [theme.breakpoints.down('md')]: {
        fontSize: '28px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '24px',
      },
    }),
    h6: ({ theme }) => ({
      fontSize: '24px',
      lineHeight: 1.1,
      [theme.breakpoints.down('lg')]: {
        fontSize: '20px',
      },
    }),
    subtitle1: {
      fontSize: '18px',
      fontWeight: 500,
      lineHeight: 1.2,
    },
    subtitle2: {
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: 1.2,
    },
    body1: {
      fontSize: '16px',
      lineHeight: 1.2,
    },
    body2: {
      fontSize: '14px',
      lineHeight: 1.4,
    },
    caption: {
      fontSize: '12px',
      lineHeight: 1.4,
    },
  },
};
