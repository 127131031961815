import { type PaletteOptions } from '@mui/material';

declare module '@mui/material/styles' {
    interface TypeBackground {
      default: string;
      paper: string;
      gradient: string;
      grey: string;
    }

    interface Palette {
      border: { default: string };
    }
    interface PaletteOptions {
      border?: { default: string };
    }
  }

export const palette: PaletteOptions = {
  primary: {
    main: '#FF725C',
  },
  secondary: {
    main: '#F8F8F8',
  },
  
};
