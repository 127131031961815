import { SvgIcon, type SvgIconProps } from '@mui/material';
import React, { type FC, memo } from 'react';

const selfTransferIcon: FC<SvgIconProps> = ({ sx, fontSize, color }) => (
  <SvgIcon viewBox="0 0 19 17" sx={sx} fontSize={fontSize} color={color}>
    <g clipPath="url(#clip0_475_23)">
      <path
        d="M14.1016 0.782745C14.4994 0.782745 14.8809 0.940781 15.1622 1.22209C15.4435 1.50339 15.6016 1.88492 15.6016 2.28275C15.6016 2.68057 15.4435 3.0621 15.1622 3.34341C14.8809 3.62471 14.4994 3.78275 14.1016 3.78275C13.7037 3.78275 13.3222 3.62471 13.0409 3.34341C12.7596 3.0621 12.6016 2.68057 12.6016 2.28275C12.6016 1.88492 12.7596 1.50339 13.0409 1.22209C13.3222 0.940781 13.7037 0.782745 14.1016 0.782745ZM11.7359 4.739L12.0484 4.8015L14.5484 5.3015L15.0266 5.39837L15.2453 5.83587L16.3453 8.039L17.5484 8.639L16.6547 10.4265L15.1547 9.6765L14.8578 9.52649L14.7078 9.22962L14.2797 8.37337L13.7047 10.3234L15.3391 12.1046L15.5016 12.2827L15.5641 12.5171L16.3141 15.2671L16.5766 16.2327L14.6484 16.7577L14.3859 15.7921L13.6984 13.2765L11.4828 10.8577C11.0203 10.3546 10.8484 9.6515 11.0234 8.99212L11.5547 7.00462L10.4078 7.514L10.0484 8.59525L9.73281 9.54525L7.83594 8.914L8.15156 7.964L8.65156 6.464L8.79219 6.04525L9.19531 5.86712L11.4453 4.86712L11.7359 4.739ZM10.5297 11.2702C10.5953 11.364 10.6703 11.4515 10.7484 11.539L12.0203 12.9265L11.5297 14.1546L11.4547 14.3452L11.3109 14.489L9.81094 15.989L9.10156 16.6984L7.68594 15.2827L8.39219 14.5765L9.74844 13.2202L10.5297 11.2702ZM5.06719 16.7796L1.60156 14.7796L4.60156 9.58275L7.23906 11.1046L8.17031 9.52649L9.03281 10.0359L5.96094 15.2327L5.78594 15.5296L5.06406 16.7796H5.06719Z"
        fill="#0B5D95"
      />
    </g>
    <defs>
      <clipPath id="clip0_475_23">
        <rect width="18" height="16" fill="white" transform="translate(0.601562 0.782745)" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default memo(selfTransferIcon);
